.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.912);
    display: flex;
    justify-content: center;
    align-items: center;
    /* Align modal towards the top *
    /* Add padding to position the modal down slightly */
}


.modal-content {
    position: relative;
    align-items: center;
    text-align: center;
    max-width: 70vw;
    /* Limit width based on viewport width */
    max-height: 70vh;
    /* Limit height based on viewport height */
    display: flex;
    flex-direction: column;

    /* This box should not eat the click events (so that clicks are sent to the background) */
    pointer-events: none;

    &>* {
        /* ... but the children need the default behavior, so that clicking on the picture doens't close it */
        pointer-events: initial;
    }
}

.modal-photo {
    /* Make sure the photo doesn't exceed modal's max width */
    min-height: 0;
    max-width: 100%;
    display: flex;
    box-sizing: border-box;
    /* Make sure the photo doesn't exceed modal's max height */
    border: 5px solid white;
    /* padding-bottom: 20px; */
}