.about-container {
    padding: 10px;
    /* min-height: calc(100%); */
    /* height: fit-content; */
    font-weight: bold;
}

.maybe-grid {
    /* margin-left: 5vw;
    margin-right: 15vw; */
    display: grid;
    /* grid-template-columns: repeat(2, 50%); */
    grid-template-columns: 40% 30%;
    grid-template-rows: min-content;
    /* align-items: left; */
    /* align-self: center; */
    /* gap: 10px; */
    align-items: left;
    column-gap: 10vh;
    row-gap: 15px;
    height: fit-content;
}

.text-div {
    /* width: 80%; */
    /* flex: auto; */
    height: fit-content;
    align-items: left;
    margin-top: 2vh;
    align-self: right;
    padding-right: 20px;
    padding-bottom: 2vh;
    padding-left: 2vh;
    /* background-color: cadetblue; */

}

.picture-div {
    flex: auto;
    height: fit-content;
    align-items: left;
    margin-top: 2vh;
    padding-top: 2vh;
    /* padding-bottom: 2vh; */
    /* margin-right: 0px; */
    /* background-color: goldenrod; */
}

.img_details {
    border-color: #eab700;
    height: 800px;
    /* border-radius: 10px; */
    /* border: 10px; */
}