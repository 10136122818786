/* Ensure the entire layout takes up the full height of the viewport */
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* height: fit-content; */
    /* Full viewport height */
}

/* Header styles
.header {
    background-color: #333;
    color: white;
    padding: 10px 0;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-family: Great Vibes; */
/* } */

/* Your name styling */
.site-name {
    /* this is where it says Elizabeth Stephenson in big print on the header */
    font-size: 50px;
    /* supercede the normal header and h1/2/3 rules and have a different font */
    font-family: Great Vibes;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    font-weight: bold;
    color: white;
}

/* Navigation styles */
.nav {
    margin-top: 10px;
}

.nav-list {
    list-style: none;
    display: flex;
    gap: 40px;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.nav-list li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    text-align: center;
    align-items: center;
}

.nav-list li a:hover {
    text-decoration: underline;
}

/* Main content should take up remaining space */
.main-content {
    flex: 1;
    /* Ensures the main content area grows to fill remaining space */
    padding: 20px;
}

/* Footer styles
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    /* font-family: Great Vibes; */
/* padding: 15px 0;
    width: 100%;
    margin-top: auto; */
/* Pushes the footer to the bottom */
/* } */