.App {
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 10px;
}



/* h1 is largest heading */
/* General rules for fonts and colors */
h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 45pt;
  font-family: Comfortaa;
  font-variant: normal;
  color: #555;
}

.h1_left_align {
  display: flex;
  flex-direction: row;
  padding-bottom: 10pt;
  margin-left: 20pt;
  text-align: left;
  font-size: 30pt;
  font-family: Rubik Mono One;
  letter-spacing: 5px;
  font-variant: normal;
  color: #555;
}


.h1_photo_gallery {
  /* this is the big text that says PHOTO GALLERY */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 45pt;
  font-family: Rubik Mono One;
  font-variant: normal;
  letter-spacing: 1dvh;
  font-weight: 800;
  flex-wrap: inherit;
  color: #555;
}

.gallerydescript {
  font-family: Reenie Beanie;
  font-size: 35pt;
  font-weight: 600;
  margin-top: -20pt;
  margin-bottom: 20pt;
  word-wrap: break-word;
}

iframe {
  flex-direction: row;
  align-items: left;
  margin-left: 20pt;
}

h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: 25pt;
  margin-left: 20pt;
  font-family: Rubik Mono One;
  font-variant: normal;
  font-weight: 300;
  color: #555;
}




p {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 15pt;
  font-family: Comfortaa;
  font-variant: normal;
  color: #555;
}

.p_left_align {
  display: flex;
  flex-direction: row;
  padding-bottom: 18pt;
  margin-left: 20pt;
  text-align: left;
  font-size: 15pt;
  font-family: Comfortaa;
  font-variant: normal;
  color: #555;
  word-wrap: break-word;
  line-height: 130%;

}

body {
  /* font-size: 12pt; */
  font-family: Comfortaa;
  font-variant: normal;
  color: #555;
  text-align: center;
  /* something like below to force footer down? */
  /* min-height: calc(100vh - 70px); */
}


.modal-text {
  /* this is p, but different color */
  color: white;
  margin-top: 10px;
  word-wrap: break-word;
  text-align: center;
  padding-top: 20px;
  max-width: 70%;
}

header {
  font-size: 40;
  font-family: Comfortaa;
  font-variant: normal;
  background-color: #333;
  color: white;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* See .site-name in layout.css for the site-name styling */
}

footer {
  background-color: #333;
  text-align: center;
  /* padding: 15px 0; */
  width: 100%;
  min-height: 70px;
  /* margin-top: 100vh; */
  /* Pushes the footer to the bottom */
  /* position: fixed; */
  /* position: static; */
  /* margin-left: 0;
  margin-right: 0;
  margin-bottom: 0px;
  bottom: 0px;
  height: 100%; */
  /* position: absolute; */
  right: 0;
  bottom: 0;
  left: 0;
  /* padding: 1rem; */
  /* height: 100% */
}

.p_footer {
  /* Override normal p settings */
  font-size: 10pt;
  font-family: Comfortaa;
  font-variant: normal;
  text-align: center;
  padding: 10px;
  color: white;

}

.imagestyle_bl {
  margin: 10px;
  width: 340px;
  border: 2px solid black;
  cursor: pointer;
  transition: transform 0.2s;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: bottom left;
}

.imagestyle_br {
  margin: 10px;
  width: 340px;
  border: 2px solid black;
  cursor: pointer;
  transition: transform 0.2s;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: bottom right;
}

iframe {
  align-items: left;
  align-content: left;
}



@media only screen and (width <=2000px) {
  /* body {
    background-color: darksalmon;
  } */

  iframe {
    width: 30vw;
    height: 20vw;
  }

  .maybe-grid {
    grid-template-columns: 50% 30%;
    column-gap: 30px;
  }

  /* .gallerydescript {
    font-family: Reenie Beanie;
    font-size: 35pt;
    font-weight: 600;
    margin-top: -20pt;
  } */

}


@media only screen and (width <=1000px) {
  /* body {
    background-color: lightblue;
    align-items: left;
    text-align: left;
  } */

  .gallerydescript {
    font-size: 27pt;
    margin-top: -10pt;
    margin-bottom: 10pt;
  }

  h2 {
    font-size: 15pt;
    word-wrap: break-word;
  }

  p {
    font-size: 14pt;
    word-wrap: break-word;
  }

  .maybe-grid {
    grid-template-columns: min-content;
    grid-template-rows: min-content;
    row-gap: 0px;
    column-gap: 0px;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: left;
    align-content: left;
  }

  .img_details {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 40vw;
    height: 60vw;
    object-position: bottom left;
    align-items: left;
    align-self: left;
  }

  .picture-div {
    padding-left: 2vh;
    margin-left: 2vh;
    display: flex;

  }

  .text-div {
    align-items: left;
    align-self: left;
    align-content: left;
  }

  iframe {
    width: 50vw;
    height: 30vw;
  }

  .p_left_align {
    font-size: 10pt;
    flex-wrap: wrap;
    word-wrap: break-word;
  }

  .h1_photo_gallery {
    font-size: 30pt;
    word-wrap: break-word;
  }

  .h1_left_align {
    font-size: 15pt;
    word-wrap: break-word;
  }

  .about-container {
    align-items: left;
    align-content: left;
    align-self: left;
  }
}

@media only screen and (width <=640px) {
  /* body {
    background-color: darkgrey;
    word-wrap: break-word;
  } */

  .gallerydescript {
    font-size: 19pt;
    margin-top: -5pt;
    margin-bottom: 0pt;
    word-wrap: break-word;
  }

  h2 {
    font-size: 8pt;
    word-wrap: break-word;
    padding: 0px;
  }

  .h1_left_align {
    padding: 0px;
  }


  p {
    font-size: 12pt;
    word-wrap: break-word;
    flex-wrap: wrap;
  }

  .p_left_align {
    font-size: 8pt;
    word-wrap: break-word;
    flex-wrap: wrap;
    padding-top: 0px;
  }

  .h1_photo_gallery {
    font-size: 20pt;
    word-wrap: break-word;
  }

  .h1_left_align {
    font-size: 10pt;
    word-wrap: break-word;
  }

  .text-div {
    padding-left: 0px;
  }

  .picture-div {
    padding-left: 0px;
    margin-left: 2vh;
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 0px;

  }

  .img_details {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 50vw;
    height: 70vw;
    object-position: bottom left;
    align-items: left;
    align-self: left;
  }

  .about-container {
    padding: 1px;
  }
}