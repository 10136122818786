.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    justify-content: center;
    padding: 20px;
}

.gallery-container-links {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    justify-content: center;
    padding: 20px;
    /* Here we are using  p rules from app.css*/

    .photo {
        display: flex;
        flex-direction: column;
        align-items: center;


        span {
            margin-inline: 10px;
        }
    }
}

@media (width <=960px) {
    .gallery-container {
        grid-template-columns: repeat(2, 320px);
    }

    ;

    .gallery-container-links {
        grid-template-columns: repeat(2, 320px);
    }
}

@media (width <=640px) {
    .gallery-container {
        grid-template-columns: repeat(1, 320px);
    }

    ;

    .gallery-container-links {
        grid-template-columns: repeat(1, 320px);
    }
}

.gallery-photo {
    margin: 10px;
    width: 300px;
    border: 2px solid black;
    cursor: pointer;
    transition: transform 0.2s;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: top;
    /* obj-pos is needed for the sheep photo to display with a good preview. */
}

.gallery-photo-br {
    margin: 10px;
    width: 300px;
    border: 2px solid black;
    cursor: pointer;
    transition: transform 0.2s;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: bottom right;
    /* obj-pos is needed for the sheep photo to display with a good preview. */
}

.gallery-photo-bl {
    margin: 10px;
    width: 300px;
    border: 2px solid black;
    cursor: pointer;
    transition: transform 0.2s;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: bottom left;
    /* obj-pos is needed for the sheep photo to display with a good preview. */
}

.gallery-photo:hover {
    transform: scale(1.05);
}

.gallery-photo-bl:hover {
    transform: scale(1.05);
}

.gallery-photo-br:hover {
    transform: scale(1.05);
}

.gallery-item {
    margin-bottom: 20px;
    /* Add some spacing between items */
    text-align: center;
    /* Center the image and text */
    font-family: Great Vibes;
}

.photo-description {
    /* Optional: change the text color */
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #555;
}